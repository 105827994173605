<template>
  <v-container>
    <!-- Title -->
    <h2 class="mb-4">Staff Management</h2>

    <!-- Add Staff Button -->
    <v-btn class="bg-primary text-white" @click="openAddStaffModal">
      Add New Staff
    </v-btn>

    <!-- Staff List Table -->
    <v-card class="mt-6">
      <v-card-title>Staff List</v-card-title>
      <v-data-table
        :headers="headers"
        :items="staffList"
        :items-per-page="options.itemsPerPage"
        :loading="loading"
        :server-items-length="totalStaff"
        :options.sync="options"
        class="elevation-1"
      >
        <!-- Action Buttons (Edit Permissions and Delete) -->
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="openEditPermissionsModal(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteStaff(item)">
            mdi-delete
          </v-icon>
        </template>

        <!-- Display Permissions -->
        <template v-slot:item.permissions="{ item }">
          <v-chip
            v-for="permission in item.permissions"
            :key="permission.id"
            small
            class="mr-1"
          >
            {{ permission.name }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>

    <!-- Add Staff Modal -->
    <v-dialog v-model="addStaffModal" max-width="700px">
      <v-card>
        <v-card-title>Add New Staff</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="addStaff">
            <v-text-field
              v-model="newStaff.name"
              label="Full Name"
              required
            ></v-text-field>
            <v-text-field
              v-model="newStaff.email"
              label="Email"
              type="email"
              required
            ></v-text-field>
            <v-text-field
              v-model="newStaff.password"
              label="Password"
              type="password"
              required
            ></v-text-field>

            <!-- Permissions Checkboxes in a 2-column layout -->
            <v-subheader>Permissions</v-subheader>
            <v-row>
              <v-col
                v-for="permission in permissions"
                :key="permission.id"
                cols="6"
              >
                <v-checkbox
                  v-model="newStaff.permissions"
                  :label="permission.name"
                  :value="permission.id"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-btn type="submit" class="bg-primary text-white">Add Staff</v-btn>
            <v-btn class="ml-3" @click="closeAddStaffModal">Cancel</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Edit Permissions Modal -->
    <v-dialog v-model="editPermissionsModal" max-width="500px">
      <v-card>
        <v-card-title>Edit Permissions</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="updatePermissions">
            <!-- Permissions Checkboxes in a 2-column layout -->
            <v-subheader>Permissions</v-subheader>
            <v-row>
              <v-col
                v-for="permission in permissions"
                :key="permission.id"
                cols="6"
              >
                <v-checkbox
                  v-model="selectedStaff.permissions"
                  :label="permission.name"
                  :value="permission.id"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-btn type="submit" class="bg-primary text-white">Save</v-btn>
            <v-btn class="ml-3" @click="closeEditPermissionsModal">Cancel</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "StaffManagement",
  data() {
    return {
      headers: [
        { text: "Full Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Permissions", value: "permissions" }, // Add permissions column
        { text: "Actions", value: "actions", sortable: false },
      ],
      staffList: [],
      totalStaff: 0,
      options: {
        page: 1,
        itemsPerPage: 5,
      },
      loading: false,
      addStaffModal: false,
      editPermissionsModal: false, // Modal for editing permissions
      newStaff: {
        name: "",
        email: "",
        password: "",
        permissions: [], // Array to store selected permission IDs
      },
      selectedStaff: {
        id: null,
        permissions: [], // Array to store selected permission IDs for editing
      },
      permissions: [], // Array to store all permissions from the backend
    };
  },
  watch: {
    options: {
      handler() {
        this.loadStaff();
      },
      deep: true,
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Staff Management" }]);
    this.loadStaff();
    this.loadPermissions(); // Fetch permissions when the component is mounted
  },
  methods: {
    loadStaff() {
      this.loading = true;

      let url = `admin/staff?per_page=${this.options.itemsPerPage}&page=${this.options.page}`;

      ApiService.get(url)
        .then(({ data }) => {
          this.staffList = data.data; // Staff list data with permissions
          this.totalStaff = data.total; // Total number of staff members
          this.loading = false;
        })
        .catch((error) => {
          console.error("Failed to load staff:", error);
          this.loading = false;
        });
    },
    loadPermissions() {
      ApiService.get("admin/get-all-permissions")
        .then(({ data }) => {
          this.permissions = data.data; // Store permissions
        })
        .catch((error) => {
          console.error("Failed to load permissions:", error);
        });
    },
    openAddStaffModal() {
      this.addStaffModal = true;
    },
    closeAddStaffModal() {
      this.addStaffModal = false;
      this.resetNewStaffForm();
    },
    resetNewStaffForm() {
      this.newStaff = {
        name: "",
        email: "",
        password: "",
        permissions: [], // Reset permissions
      };
    },
    addStaff() {
      const payload = {
        name: this.newStaff.name,
        email: this.newStaff.email,
        password: this.newStaff.password,
        permissions: this.newStaff.permissions, // Include selected permissions
      };

      ApiService.post("admin/staff", payload)
        .then(() => {
          this.closeAddStaffModal();
          this.loadStaff(); // Refresh the staff list
        })
        .catch((error) => {
          console.error("Failed to add staff:", error);
        });
    },
    openEditPermissionsModal(staff) {
      this.selectedStaff = {
        id: staff.id,
        permissions: staff.permissions.map((p) => p.id), // Map permissions to IDs
      };
      this.editPermissionsModal = true;
    },
    closeEditPermissionsModal() {
      this.editPermissionsModal = false;
      this.resetSelectedStaff();
    },
    resetSelectedStaff() {
      this.selectedStaff = {
        id: null,
        permissions: [], // Reset permissions
      };
    },
    updatePermissions() {
      const payload = {
        permissions: this.selectedStaff.permissions, // Include selected permissions
      };

      ApiService.put(`admin/staff/${this.selectedStaff.id}/permissions`, payload)
        .then(() => {
          this.closeEditPermissionsModal();
          this.loadStaff(); // Refresh the staff list
        })
        .catch((error) => {
          console.error("Failed to update permissions:", error);
        });
    },
    deleteStaff(item) {
      if (confirm("Are you sure you want to delete this staff member?")) {
        ApiService.delete(`admin/staff/${item.id}`)
          .then(() => {
            this.loadStaff(); // Refresh the staff list
          })
          .catch((error) => {
            console.error("Failed to delete staff:", error);
          });
      }
    },
  },
};
</script>